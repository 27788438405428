import React, { useEffect, useState } from "react";
import { useStoreState } from "pullstate";

import BaseModal from "@/components/Modals/BaseModal";
import { NotificationModalInterface } from "@/components/Modals/NotificationModal/NotificationModalInterface";
import Typography from "@/components/Typography";
import Switch from "@/components/Switch";
import { unsubscribeToNotification } from "@/helpers/unsubsribeToNotification";
import { subscribeToNotification } from "@/helpers/subscribeToNotification";
import Button from "@/components/Button";
import { UIStore } from "@/store/UIStore";

import styles from "./NotificationModal.module.scss";

export const NotificationModal: React.FC<NotificationModalInterface> = ({
  onClose,
  isOpen,
  slug,
  isScheduleAvailable,
}) => {
  const [isSubcribeToPodcast, setSubscibeToPodcast] = useState<boolean>(false);
  const [isSubcribeToLive, setSubscribeToLive] = useState<boolean>(false);
  const { oneSingalTags } = useStoreState(UIStore, (store) => store);

  useEffect(() => {
    if (oneSingalTags) {
      if (oneSingalTags[slug]) {
        setSubscribeToLive(true);
      }
      if (oneSingalTags[`${slug}-podcast`]) {
        setSubscibeToPodcast(true);
      }
    }
  }, [oneSingalTags]);

  const handlePodcast = () => {
    if (isSubcribeToPodcast) {
      unsubscribeToNotification(`${slug}-podcast`);
      const { [`${slug}-podcast`]: remove, ...rest } = oneSingalTags;
      UIStore.update((store) => {
        store.oneSingalTags = rest;
      });
      setSubscibeToPodcast(false);
    } else {
      subscribeToNotification(`${slug}-podcast`, "podcast");
      UIStore.update((store) => {
        store.oneSingalTags = {
          ...oneSingalTags,
          [`${slug}-podcast`]: "podcast",
        };
      });
      setSubscibeToPodcast(true);
    }
  };

  const handleLive = () => {
    if (isSubcribeToLive) {
      unsubscribeToNotification(slug);
      setSubscribeToLive(false);
    } else {
      subscribeToNotification(slug, "live");
      setSubscribeToLive(true);
    }
  };

  return (
    <BaseModal onClose={onClose} isOpen={isOpen}>
      <div className={styles.setting}>
        <Typography variant="medium" component="p" className={styles.label}>
          Powiadamiaj o nowym epizodzie podcastu
        </Typography>

        <Switch
          id="podcast"
          name="podcast push notification"
          onClick={handlePodcast}
          checked={isSubcribeToPodcast}
          readOnly
        />
      </div>

      {isScheduleAvailable && (
        <div className={styles.setting}>
          <Typography variant="medium" component="p" className={styles.label}>
            Powiadamiaj 5 minut przed audycją ON AIR
          </Typography>

          <Switch
            id="live"
            name="live push notification"
            onClick={handleLive}
            checked={isSubcribeToLive}
            readOnly
          />
        </div>
      )}

      <div className={styles.action}>
        <Button variant="secondary" onClick={onClose} className={styles.button}>
          Zamknij
        </Button>
      </div>
    </BaseModal>
  );
};

export default NotificationModal;
