//TODO: to be removed
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { useStoreState } from "pullstate";
import classNames from "classnames";

import { applicationRoutes } from "shared/helpers/applicationRouting";
import { PlayerStore } from "@/store/PlayerStore";
import useRequest from "@/helpers/useRequest";
import { apiPath } from "@/helpers/apiPath";
import { routePath } from "@/helpers/routePath";
import { getScheduleTiming } from "@/helpers/getScheduleTiming";
import { getHourMinuteFromDate } from "@/helpers/getHourMinuteFromDate";
import { CurrentScheduleInterface } from "@/helpers/pageHelpers/Common/interface";
import { CurrentSchedulePlayingInterface } from "@/components/CurrentSchedulePlaying/CurrentSchedulePlaying.interface";
import MinimalProgressBar from "@/components/Players/MinimalProgressBar";
import Typography from "@/components/Typography";
import { NotificationTypeEnum } from "@/components/NotificationButton/NotificationButton.interface";
import NotificationButton from "@/components/NotificationButton";
import { UIStore } from "@/store/UIStore";

import styles from "./CurrentSchedulePlaying.module.scss";

export const CurrentSchedulePlaying: React.FC<
  CurrentSchedulePlayingInterface
> = ({ showOnDesktop }) => {
  const {
    isSchedulePlaying,
    scheduleTitle,
    scheduleSlug,
    scheduleStart,
    scheduleEnd,
    scheduleBelongsToPortal,
  } = useStoreState(PlayerStore, (store) => store);
  const [currentTime, setCurrentTime] = useState<number>();
  const [duration, setDuration] = useState<number>();
  const { data } = useRequest(
    apiPath.CURRENTLY_PLAYING_SCHEDULE
  ) as CurrentScheduleInterface;
  const isDataAvailable = !!data;
  const isVisible = scheduleStart || scheduleEnd;
  const { userLoggedIn } = useStoreState(UIStore, (store) => store);
  const isClientSide = typeof window !== "undefined";
  const isMobileApp = process.env.NEXT_PUBLIC_MOBILE_APP === "true";
  const isMobile = isMobileApp && isClientSide;

  const setTimes = () => {
    const { currentTime, duration } = getScheduleTiming(
      scheduleStart,
      scheduleEnd
    );

    isDataAvailable
      ? PlayerStore.update((store) => {
          store.isScheduleAvailable = true;
        })
      : PlayerStore.update((store) => {
          store.isScheduleAvailable = false;
        });

    setCurrentTime(currentTime);
    setDuration(duration);
  };

  useEffect(() => {
    if (isDataAvailable) {
      // First Check
      setTimes();

      // Checks with 5s interval
      const radioInterval = setInterval(() => {
        setTimes();
      }, 5000);
      return () => clearInterval(radioInterval);
    }
  }, [data]);

  if (isDataAvailable) {
    const podcastLink = scheduleBelongsToPortal
      ? applicationRoutes.PODCAST + scheduleSlug
      : routePath.RADIO.href;

    const isNotificationVisible =
      isMobile &&
      window.RNisNotification &&
      userLoggedIn &&
      scheduleSlug &&
      scheduleBelongsToPortal;

    return isVisible ? (
      <div
        className={classNames(
          styles.wrapper,
          showOnDesktop && styles.desktopOnly
        )}
      >
        <div className={styles.info}>
          <Link href={podcastLink}>
            <a className={styles.podcast}>
              <Typography variant="h5" className={styles.thin}>
                {scheduleTitle}
              </Typography>
            </a>
          </Link>

          {isNotificationVisible && (
            <NotificationButton
              slug={scheduleSlug as string}
              type={NotificationTypeEnum.Live}
            />
          )}

          <Typography variant="medium" className={styles.time}>
            {getHourMinuteFromDate(scheduleStart)} -{" "}
            {getHourMinuteFromDate(scheduleEnd)}
          </Typography>
        </div>

        <div
          className={styles.bookmark} /* @todo: Add bookmarks when ready */
        />

        <MinimalProgressBar
          duration={duration}
          currentTime={currentTime}
          show={isSchedulePlaying}
          alignBottom
        />
      </div>
    ) : (
      <></>
    );
  }

  return <></>;
};

export default CurrentSchedulePlaying;
