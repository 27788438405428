import React, { useEffect, useRef, useState } from "react";
import { useStoreState } from "pullstate";
import classnames from "classnames";
import { useRouter } from "next/router";
import Image from "next/image";

import {
  defaultRadioArtwork,
  PlayerStore,
  psPauseAudio,
  psPlaySource,
  radioSource,
} from "@/store/PlayerStore";
import Wave from "@/components/assets/Wave";
import PlayIcon from "@/icons/play.svg";
import PauseIcon from "@/icons/pause.svg";
import CloseIcon from "@/icons/close.svg";
import { PlayerStatusTypes } from "@/helpers/pageHelpers/Common/interface";
import {
  getRadioTrackSubtitle,
  getRadioTrackTitle,
} from "@/helpers/getCurrentTrack";
import Spinner from "@/components/Spinner";
import PageWrapper from "@/components/PageWrapper";
import Container from "@/components/Container";
import MetaHead from "@/components/MetaHead";
import IconButton from "@/components/IconButton";
import Typography from "@/components/Typography";
import CurrentSchedulePlaying from "@/components/CurrentSchedulePlaying";
import useRequest from "@/helpers/useRequest";
import { apiPath } from "@/helpers/apiPath";
import { Schema } from "@/components/MetaHead/Head.interface";
import Button from "@/components/Button";
import client from "@/helpers/apiClient";
import { RadioPageInterface } from "@/helpers/pageHelpers/Radio/interface";
import { radioMetaData } from "@/helpers/pageMetaData";
import { getImgixURL } from "@/helpers/getImgixURL";
import { getUrlToLoader } from "@/helpers/getUrlToLoader";

import styles from "./Radio.module.scss";

const Radio: React.FC<RadioPageInterface> = ({ initialData }) => {
  const {
    currentSource,
    radioTitle,
    radioSubTitle,
    radioArtworkLarge,
    scheduleArtworkUrl,
    playerStatus,
    isScheduleAvailable,
  } = useStoreState(PlayerStore, (store) => store);
  const backgroundWrapper = useRef(null);
  const playerWrapper = useRef(null);
  const videoWrapper = useRef(null);
  const videoButtonWrapper = useRef(null);
  const isPlaying = playerStatus === PlayerStatusTypes.Playing;
  const isRadioSource = currentSource === radioSource;
  const isRadioPlaying = isPlaying && isRadioSource;
  const { data } = useRequest(apiPath.RADIO_AD, {
    initialData: initialData,
    revalidateOnMount: true,
  });
  const isClientSide = typeof window !== "undefined";
  const [mobileBackground, setMobileBackground] = useState<string>("");
  const [desktopBackground, setDesktopBackground] = useState<string>("");
  const [isVideoPlaying, setVideoPlaying] = useState<boolean>(false);
  const isMobile = isClientSide && window.innerWidth <= 1024;
  const router = useRouter();
  const activeRadioArtwork =
    playerStatus === PlayerStatusTypes.Playing
      ? radioArtworkLarge
      : scheduleArtworkUrl
      ? scheduleArtworkUrl
      : defaultRadioArtwork;

  useEffect(() => {
    if (data) {
      data?.mobilePicture && setMobileBackground(data.mobilePicture);
      data?.desktopPicture && setDesktopBackground(data.desktopPicture);
    }
  }, [data]);

  const pauseAudio = () => {
    PlayerStore.update(psPauseAudio);
  };

  const playAudio = () => {
    PlayerStore.update(
      psPlaySource(radioSource, {
        title: radioTitle,
        subTitle: radioSubTitle,
        artwork: radioArtworkLarge,
        slug: undefined,
        podcast: undefined,
      })
    );
  };

  // Render Controls
  const renderControls = () => {
    switch (playerStatus) {
      case PlayerStatusTypes.Loading:
        return <Spinner />;
      case PlayerStatusTypes.Playing:
      case PlayerStatusTypes.Paused:
        return isRadioPlaying ? <PauseIcon /> : <PlayIcon />;
    }
  };
  const schemaData = {
    type: Schema.Page,
    title: "newonce radio",
    slug: "radio",
  };

  const handleClickAd = (event) => {
    if (
      backgroundWrapper.current.contains(event.target) &&
      !playerWrapper.current.contains(event.target) &&
      !videoButtonWrapper?.current?.contains(event.target) &&
      data?.mobileClickTag &&
      data?.desktopClickTag
    ) {
      const url = new URL(
        isMobile ? data?.mobileClickTag : data?.desktopClickTag
      );
      const isInternal = process.env.NEXT_PUBLIC_DOMAIN.includes(url.host);

      if (isInternal) {
        router.push(url.pathname);
      } else {
        window.open(url.href, "_blank");
      }

      return;
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickAd);

    return () => {
      document.removeEventListener("mousedown", handleClickAd);
    };
  }, []);

  const playVideo = () => {
    pauseAudio();
    videoWrapper.current.play();
    setVideoPlaying(true);
  };

  const stopVideo = () => {
    videoWrapper.current.pause();
    videoWrapper.current.currentTime = 0;
    setVideoPlaying(false);
    playAudio();
  };

  return (
    <>
      <PageWrapper pageData={{ headerLabel: "Radio" }}>
        <MetaHead metaData={radioMetaData} schemaData={schemaData} />

        <div
          className={classnames(
            styles.fullScreenBackground,
            (data?.mobileClickTag || data?.desktopClickTag) && styles.clickable
          )}
          style={{
            backgroundImage: `url(${
              isClientSide && window.innerWidth < 1024
                ? mobileBackground
                : desktopBackground
            })`,
          }}
          ref={backgroundWrapper}
        >
          <Typography component="h1" className={styles.hideHeading}>
            Radio live newonce
          </Typography>
          <Container
            className={classnames(
              styles.radioContainer,
              isScheduleAvailable && styles.additionalPadding
            )}
          >
            {data?.desktopVideo && data?.showDesktopCreation && (
              <>
                <video
                  playsInline
                  controls
                  poster={desktopBackground}
                  ref={videoWrapper}
                  className={classnames(
                    styles.video,
                    isVideoPlaying && styles.playing
                  )}
                  onEnded={stopVideo}
                  disablePictureInPicture
                  controlsList="nodownload"
                >
                  <source src={data.desktopVideo.url} type="video/mp4" />
                </video>

                {!isVideoPlaying ? (
                  <Button
                    onClick={playVideo}
                    variant="secondary"
                    className={styles.playVideoButton}
                    ref={videoButtonWrapper}
                  >
                    Odtwórz video
                  </Button>
                ) : (
                  <IconButton
                    variant="primary"
                    onClick={stopVideo}
                    className={styles.closeVideoButton}
                    ref={videoButtonWrapper}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </>
            )}
            <div
              className={classnames(
                styles.radioWrapper,
                isVideoPlaying && styles.isVideoPlaying
              )}
              ref={playerWrapper}
            >
              <div
                className={classnames(
                  styles.radioDetails,
                  isScheduleAvailable && styles.playing
                )}
              >
                <div className={styles.artworkWrapper}>
                  <Image
                    layout="fill"
                    objectFit="cover"
                    src={getImgixURL(activeRadioArtwork)}
                    alt={`${radioTitle} - ${radioSubTitle}`}
                    loader={() => getUrlToLoader(activeRadioArtwork)}
                  />

                  <div
                    className={classnames(
                      styles.radioControls,
                      isRadioPlaying && styles.playing
                    )}
                  >
                    <IconButton
                      className={styles.playButton}
                      variant="primary"
                      size="big"
                      onClick={isRadioPlaying ? pauseAudio : playAudio}
                    >
                      {renderControls()}
                    </IconButton>
                  </div>
                </div>

                <div className={styles.currentlyPlaying}>
                  <div className={styles.playingDetails}>
                    <Typography
                      className={styles.track}
                      component="b"
                      variant="medium"
                    >
                      {getRadioTrackTitle()}
                    </Typography>

                    <Typography className={styles.track} variant="medium">
                      {getRadioTrackSubtitle()}
                    </Typography>
                  </div>

                  <div
                    className={classnames(
                      styles.wave,
                      isRadioPlaying && styles.active
                    )}
                  >
                    <Wave className={styles.animatedWave} />
                  </div>
                </div>
              </div>

              <CurrentSchedulePlaying showOnDesktop />
            </div>
          </Container>
        </div>
      </PageWrapper>
      <script
        type="text/javascript"
        src="https://ad.doubleclick.net/ddm/trackimpj/N186801.3051743NEWONCEPL/B25580803.299922294;dc_trk_aid=493010037;dc_trk_cid=148640964;ord=%%CACHEBUSTER%%;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=?"
        async
      />
    </>
  );
};

export async function getStaticProps() {
  const { data } = await client.get(apiPath.RADIO_AD);

  return {
    props: {
      initialData: data,
    },
    revalidate: 600,
  };
}

export default Radio;
