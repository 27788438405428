import dayjs from "dayjs";
import "dayjs/locale/pl";

dayjs.locale("pl");

export const getScheduleTiming = (
  scheduleStart: string,
  scheduleEnd: string
): { currentTime: number; duration: number } => {
  const today = dayjs();
  const start = dayjs(scheduleStart);
  const end = dayjs(scheduleEnd);
  const duration = end.diff(start, "second");
  const currentTime = today.diff(start, "second");

  return { currentTime, duration };
};
