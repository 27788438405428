import React, { useEffect, useState } from "react";
import humps from "humps";
import { useStoreState } from "pullstate";
import classNames from "classnames";
import * as Sentry from "@sentry/react";

import {
  NotificationButtonInterface,
  NotificationTypeEnum,
} from "@/components/NotificationButton/NotificationButton.interface";
import IconButton from "@/components/IconButton";
import BellIcon from "@/icons/bell.svg";
import client from "@/helpers/apiClient";
import { UIStore } from "@/store/UIStore";
import { oneSignalAPI } from "@/helpers/apiPath";
import { subscribeToNotification } from "@/helpers/subscribeToNotification";
import { unsubscribeToNotification } from "@/helpers/unsubsribeToNotification";
import NotificationModal from "@/components/Modals/NotificationModal";

export const NotificationButton: React.FC<NotificationButtonInterface> = ({
  slug,
  type,
  className,
  isScheduleAvailable,
  variant,
  size,
}) => {
  const [isMarked, setMarked] = useState<boolean>(false);
  const [isModalOpen, setModalState] = useState<boolean>(false);
  const { oneSingalTags, userID } = useStoreState(UIStore, (store) => store);
  const oneSingalId = window.RNOneSignalID;

  const handleNotification = () => {
    if (isMarked) {
      unsubscribeToNotification(slug);
      const { [slug]: remove, ...rest } = oneSingalTags;
      UIStore.update((store) => {
        store.oneSingalTags = rest;
      });
      setMarked(false);
    } else {
      subscribeToNotification(slug, type);
      UIStore.update((store) => {
        store.oneSingalTags = { ...oneSingalTags, [`${slug}`]: "live" };
      });
      setMarked(true);
    }
  };

  const handleClick = () => {
    switch (type) {
      case NotificationTypeEnum.Live:
        return handleNotification();
      case NotificationTypeEnum.Podcast:
        return setModalState(true);
    }
  };

  useEffect(() => {
    const setExternalID = async () => {
      try {
        await client.put(oneSignalAPI(oneSingalId), {
          external_user_id: userID,
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    };

    const getNotificationStatus = () => {
      client
        .get(oneSignalAPI(oneSingalId))
        .then((response) => {
          const tags = humps.decamelizeKeys(response.data.tags, {
            separator: "-",
          });
          UIStore.update((store) => {
            store.oneSingalTags = tags;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (oneSingalId) {
      setExternalID();
      getNotificationStatus();
    }
  }, []);

  useEffect(() => {
    if (
      oneSingalTags &&
      (oneSingalTags[slug] || oneSingalTags[`${slug}-podcast`])
    ) {
      setMarked(true);
    } else {
      setMarked(false);
    }
  }, [oneSingalTags]);

  return (
    <>
      <IconButton
        className={classNames(className, "pushReminder")}
        variant={variant}
        size={size}
        onClick={handleClick}
        active={isMarked}
      >
        <BellIcon />
        {variant === "withLabel" && (
          <span>{isMarked ? "Włączone" : "Włącz powiadomienia"}</span>
        )}
      </IconButton>

      {type === NotificationTypeEnum.Podcast && (
        <NotificationModal
          isOpen={isModalOpen}
          onClose={() => setModalState(false)}
          slug={slug}
          isScheduleAvailable={isScheduleAvailable}
        />
      )}
    </>
  );
};

export default NotificationButton;
