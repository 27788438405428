import { ClassName } from "@/helpers/pageHelpers/Common/interface";

import {
  IconButtonSizes,
  IconButtonTypes,
} from "../IconButton/IconButton.interface";

export enum NotificationTypeEnum {
  Live = "live",
  Podcast = "podcast",
}

export interface NotificationButtonInterface {
  slug: string;
  type: NotificationTypeEnum;
  isScheduleAvailable?: boolean;
  className?: ClassName;
  variant?: IconButtonTypes;
  size?: IconButtonSizes;
}
